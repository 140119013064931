.course-item {
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease, text-decoration 0.2s ease;
}

.course-item:hover {
  transform: scale(1.1);
  color: #007bff;
  text-decoration: underline;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border-radius: 5px;
}

  
  /* Smooth modal animation */
  .modal-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  
  .modal-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .modal-exit {
    opacity: 1;
  }
  
  .modal-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  
  .modal-backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  