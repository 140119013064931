/* In RegisterModal.css */
@keyframes check-animation {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  
  /* Other styles specific to RegisterModal */
.check-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    color: green; /* Change to your preferred color */
    font-size: 1.2rem;
  }
/* Base styles for date input */
.dateclass {
  width: 100%;
  padding: 10px 12px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #fff;
  color: #495057;
  position: relative;
}

/* Apply placeholder simulation only on smaller screens */
@media (max-width: 768px) {
  .dateclass.placeholderclass::before {
    content: attr(placeholder); /* Display the placeholder text */
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    color: #495057; /* Match desktop color */
    font-size: 0.9rem; /* Slightly smaller font size */
    font-family: inherit; /* Ensure consistent font family */
    font-weight: normal; /* Match font weight with desktop */
    pointer-events: none;
  }

  /* Remove placeholder on hover */
  .dateclass.placeholderclass:hover::before {
    content: '';
    width: 0%;
  }
}
.btn-outline-secondary {
  border: none;
  background: transparent;
  cursor: pointer;
}
.input-group {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.input-group.hidden {
  opacity: 0;
  pointer-events: none;
  height: 0;
}
.custom-gray-btn {
  background-color: #6c757d; /* Custom gray */
  color: white;
  border: none;
}

.custom-gray-btn:hover {
  background-color: #5a6268; /* Darker gray for hover */
  color: white;
}

.custom-gray-btn:disabled {
  background-color: #adb5bd; /* Lighter gray for disabled state */
  cursor: not-allowed;
}