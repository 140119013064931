.password-toggle-icon {
    font-size: 1.2rem;
    color: #6c757d;
    z-index: 2;
  }
  
  .password-toggle-icon:hover {
    color: #343a40;
  }
  
  .input-group {
    position: relative;
  }
  
  .input-group .form-control {
    padding-right: 40px; /* Space for the eye icon */
  }
  
  .input-group .input-group-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensures it matches the height of the input */
  }
  