.chatbot {
   min-height: 400px;
   width: 510px;
   position: fixed;
   bottom: 2%;
   right: 1%;
   border-radius: 10px;

   z-index: 999;
}

.chatbot-avatar {
   width: 45px;
}
.chatbot-close,
.chatbot-send {
   cursor: pointer;
   font-size: 1.45rem;
}

.chathead-container {
   display: flex;
   align-items: center;
   position: fixed;
   bottom: 5%;
   right: 3%;
}
.chathead {
   width: 70px;
   cursor: pointer;
   transition: all 0.3s;
}
.chathead:hover {
   width: 80px;
}
.chathead-message {
   font-size: 0.95rem;
   border-radius: 8px;
   padding: 10px;
   background-color: var(--grey);
   position: relative;
   margin-right: 18px;
}
.chathead-message::before {
   content: '';
   position: absolute;
   width: 0;
   height: 0;
   bottom: 25%;
   left: 99.5%;
   border: 10px solid transparent;
   border-bottom-color: var(--grey);
   transform: rotate(90deg);
}

.chatbot-header {
   padding: 10px 15px;
   border-radius: 10px 10px 0 0;
}
.chatbot-messages {
   width: 100%;
   height: 420px;
   background-color: var(--white);
   padding-top: 12px;
   -ms-overflow-style: none; /* for Internet Explorer, Edge */
   scrollbar-width: none; /* for Firefox */
   overflow-y: scroll;
   scroll-behavior: smooth;
}
.chatbot-messages::-webkit-scrollbar {
   display: none; /* for Chrome, Safari, and Opera */
}

.chatbot-text-input {
   display: flex;
   align-items: center;
   padding: 12px;
   background-color: var(--white);
   border-top: solid 2px #f1f1f1;
   border-radius: 0 0 10px 10px;
}
.chatbot-text-input > input {
   width: 100%;
   border: none;
   outline: none;
   font-size: 1rem;
   width: 94%;
}

.chatbot-send {
   background-color: transparent;
   border: 0;
   outline: 0;
}

/* message */
.message {
   display: flex;
   align-items: flex-end;
   padding: 7px 18px;
}
.message.user {
   justify-content: flex-end;
}

.message-text {
   font-size: 0.9rem;
   border-radius: 8px;
   padding: 10px;
}
.message-avatar {
   margin-right: 10px;
}
.message-text.bot {
   background-color: var(--grey);
}
.message-loading {
   width: 35px;
}
.message-text.user {
   color: var(--white);
   background-color: var(--primary);
}
.message-text-link {
   font-size: inherit;
   color: var(--primary) !important;
}
.message-text-link:active,
.message-text-link:focus {
   box-shadow: none !important;
}
.message-text-link:hover {
   color: var(--primary-dark) !important;
}

/* recommended courses list */
.recommendedCourses {
   padding-left: 35px;
   font-size: 0.9rem !important;
   margin: 0;
}
.recommendedCourses > li {
   font-size: inherit;
}

/* card */
.message-cards {
   overflow: hidden;
   padding: 7px 18px;
   display: flex;
   align-items: flex-end;
}

.message-cards > .cards {
   overflow: auto;
   padding-bottom: 10px;
}

.message-cards > .cards .card-container:last-child {
   padding-right: 0;
}

.card {
   border: none !important;
}
.card-container {
   float: left;
   padding-right: 20px;
   width: 270px;
}
.card-body {
   display: flex !important;
   flex-direction: column !important;
   align-items: center !important;
   justify-content: center !important;
   padding: 0 0 10px 0 !important;
   background-color: var(--grey) !important;
   border-radius: 10px !important;
}
.card-video {
   width: 200px;
   height: 150px;
}
.card-title {
   padding: 10px 8px 0 10px !important;
   margin: 0 !important;
   text-align: center !important;
   font-size: 0.9rem !important;

   width: 100%;
   height: 100%;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
}

.message-quick-replies {
   display: flex;
   flex-wrap: wrap;
   padding: 0px 15px 8px 70px;
}

.message-quick-reply-btn {
   font-size: 0.9rem;
}

.course-recommendation {
   padding: 12px 15px;
   cursor: pointer;
}
.course-recommendation span {
   font-size: 0.95rem;
}

.course-recommendation:hover {
   color: var(--primary);
   border-bottom: solid 1px var(--primary) !important;
}

.course-recommendation.active {
   color: var(--primary) !important;
   border-bottom: none !important;
   border-top: none !important;
   border-right: none !important;
   border-left: solid 3px var(--primary) !important;
}

@media (max-width: 768px) {
   .chatbot {
      width: 90%;
      top: 50%;
      left: 50%;
      bottom: initial;
      right: initial;
      transform: translate(-50%, -50%);
   }

   .chatbot .message-text {
      font-size: 0.85rem;
   }
}

@media (max-height: 500px) {
   .chatbot {
      height: 400px;
      overflow-y: auto;
   }
}
/* Feedback container */
.feedback-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

/* Rating stars */
.feedback-rating {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin: 10px 0;
}

.star {
    transition: transform 0.2s ease-in-out;
}

.star:hover {
    transform: scale(1.2);
}

/* Emoji feedback */
.emoji-feedback {
    font-size: 2rem;
    margin-top: 5px;
    text-align: center;
}

/* Feedback button */
.feedback-submit {
    width: 100%;
    font-weight: bold;
    background-color: #007bff;
    border-radius: 10px;
}

.feedback-submit:hover {
    background-color: #0056b3;
}
.view-more-container {
   text-align: center;
   margin-top: 5px;
}

.view-more-container button {
   font-size: 12px; /* Smaller button */
   padding: 4px 8px; /* Reduce padding */
   border-radius: 5px; /* Slightly rounded */
}
