/* Header.css */
@media (max-width: 992px) {
    .navbar-nav .dropdown-menu {
      position: relative !important; /* Ensures the dropdown stays within the flow */
      float: none; /* Prevents floating */
      width: auto; /* Auto width for proper centering */
      text-align: center; /* Centers the text */
      margin: 0 auto; /* Centers the dropdown itself */
      box-shadow: none; /* Removes dropdown shadow */
      background-color: white; /* Matches the navbar background */
      border: none; /* Removes unnecessary borders */
    }
  
    .navbar-nav .dropdown-item {
      padding: 0.75rem 1rem; /* Adjust padding for better touch targets */
      text-align: center; /* Centers the text inside dropdown items */
    }
  
    /* Adjust the dropdown toggle */
    .navbar-nav .dropdown-toggle {
      text-align: center; /* Centers the toggle text */
      display: block; /* Ensures it takes up the full width */
      font-weight: 500; /* Slightly bold text for better visibility */
    }
  }
  